import React from 'react';
import './Research.css';

const Research = () => {
  return (
    <div>
      <div className="container-fluid aboutimage">
        <div className="container">
          <div className="row">
            <div className="col-12 mt-4">
              <center>
                <h2 className="text-white display-3 aboutsmt">Explore Our Gallery</h2>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container">
          <h1 className="fw-light text-center mt-4 mb-0">Our Gallery</h1>
          <hr className="mb-5 bg-primary" />
          <div className="row">
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="newgallery1.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="newgallery2.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="newgallery3.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="newgallery4.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="newgallery5.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="newgallery6.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="newgallery7.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="newgallery8.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="labgallery5.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="labgallery6.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="labgallery7.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="labgallery8.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="labgallery1.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="labgallery2.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="labgallery3.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <a href="#" className="d-block mb-2">
                <img src="labgallery4.jpg" alt="" className="img-fluid img-thumbnail" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;
